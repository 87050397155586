import React from 'react';

import { RouteComponentProps } from '@reach/router';

import { OneColumn } from '@components/Layouts/OneColumn';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';
import { useSetUserIndividualId } from '@hooks/setLoggedInUserId';

const DisasterResponseFundPage: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();

  useSetUserIndividualId()
  
  return (
    <OneColumn className="mb-20">
      <div className="widgetWrapper">
        <div id="donate-widget">
          <Helmet
            titleTemplate={`${t(
              'metadata.title.disaster-response-fund',
              'Disaster Response Fund'
            )} | ${t('metadata.title.default', 'My Rotary')}`}
          />
        </div>
      </div>
    </OneColumn>
  );
};

export default DisasterResponseFundPage;
